import React from "react";
import "./Contact.css";
import Image from "../../images/contact.jpg";
import { MdCall } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import { HiChatBubbleBottomCenter } from "react-icons/hi2";
function Contact() {
  return (
    <section className="c-wrapper">
      <div className="paddings innerWidth c-container">
        {/* leftside */}
        <div className="flexColStart c-left">
          <span className="orangeText">Our Contact Us</span>
          <span className="primaryTexe">Easy to contact us</span>
          <span className="secondaryText">
            We always ready to help by providijng the best services for you. We{" "}
            <br /> beleive a good blace to live can make your life better
          </span>

          <div className="flexColStart contactModes">
            {/* firstrow */}
            <div className="flexStart row">
                <div className="flexColCenter mode">
                    <div className="flexStart">
                        <div className="flexColCenter icon">
                            <MdCall size={25} />
                        </div>
                        <div className="flexColStart detail">
                            <span className="primaryText">Call</span>
                            <span className="secondaryText">021 123 145 23</span>
                        </div>
                    </div>
                    <div className="flexCenter main-btn">Call Now</div>
                 </div>

                <div className="flexColCenter mode">
                    <div className="flexStart">
                        <div className="flexColCenter icon">
                            <HiChatBubbleBottomCenter size={25} />
                        </div>
                        <div className="flexColStart detail">
                            <span className="primaryText">Chat Call</span>
                            <span className="secondaryText">021 123 145 23</span>
                        </div>
                    </div>
                    <div className="flexCenter main-btn">Chat Now</div>
                </div>
           
            </div>
            {/* secondRow */}
            <div className="flexStart row">
                <div className="flexColCenter mode">
                    <div className="flexStart">
                        <div className="flexColCenter icon">
                            <MdCall size={25} />
                        </div>
                        <div className="flexColStart detail">
                            <span className="primaryText">Video</span>
                            <span className="secondaryText">021 123 145 23</span>
                        </div>
                    </div>
                    <div className="flexCenter main-btn">Video Call Now</div>
                 </div>

                <div className="flexColCenter mode">
                    <div className="flexStart">
                        <div className="flexColCenter icon">
                            <BsFillChatDotsFill size={25} />
                        </div>
                        <div className="flexColStart detail">
                            <span className="primaryText">Messege</span>
                            <span className="secondaryText">021 123 145 23</span>
                        </div>
                    </div>
                    <div className="flexCenter main-btn">Messege Now</div>
                </div>
          
            </div>
          
          </div>
        </div>
        <div className="flexCenter c-right">
          <div className="img-container image">
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
