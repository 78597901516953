import React from "react";
import "./header.css";
import {BiMenuAltRight} from 'react-icons/bi'
import OutsideClickHandler from "react-outside-click-handler";
import logo from "../../images/logo.png";
import { useState } from "react";
function Header() {
  const [menuOpened,setMenuOpened] =useState(false)

    const getMenuStyles = (menuOpened) => {
      if(document.documentElement.clientWidth <= 800)
      {
        return {right: !menuOpened && "-100%"}
      }
    }
  return (
    <section className="h-wrapper">
      <div className=" paddings innerWidth h-container">
        <img src={logo} alt="" width={100} />

      <OutsideClickHandler 
      onOutsideClick={()=> setMenuOpened(false)}>
        
        <div className="flexCenter header "
        style={getMenuStyles(menuOpened)}>
          <a href="" className="anchor">Residencies</a>
          <a href="">Our Value</a>
          <a href="">Contact Us</a>
          <a href="">Get Started</a>
          <button class="contact" style={{color:"black"}}>Contact</button>
        </div>
      </OutsideClickHandler>
        
      <div className="menu-icon" onClick={()=> setMenuOpened((prev)=>!prev)}>
        <BiMenuAltRight size={30} />
      </div>
      </div>
    </section>
  );
}

export default Header;
