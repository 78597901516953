import React from 'react'
import './companies.css'
import Img1 from '../../images/prologis.png';
import Img2 from '../../images/tower.png';
import Img3 from '../../images/equinix.png';
import Img4 from '../../images/realty.png';
function Companies() {
  return (
        <section className="c-wrapper">
            <div className="paddings innerwidth flexCenter comp-container">
                <img src={Img1} alt="" />
                <img src={Img2} alt="" />
                <img src={Img3} alt="" />
                <img src={Img4} alt="" />
            </div>
        </section>
    )
}

export default Companies