import img1 from "../images/r1.png";
import img2 from "../images/r2.png";
import img3 from "../images/r3.png";
const Slider=[
  {
    name: "Aliva Priva Jardin",
    price: "47,043",
    detail: "Jakarta Garden City Street, Cakung. Pulo Gadung, Jakarta Timur, DKI Jakarta",
    image: img1
  },
  {
    name: "Asatti Garden City",
    price: "66,353",
    detail: "Pahlawan Street XVII No.215, Cinangka, Sawangan, Depok, Jawa Barat",
    image: img2
  },
  {
    name: "Citralan Puri Serang",
    price: "35,853",
    detail: "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    image: img3
  },
  {
    name: "Aliva Priva Jardin",
    price: "47,043",
    detail: "Jakarta Garden City Street, Cakung. Pulo Gadung, Jakarta Timur, DKI Jakarta",
    image: img1
  },
  {
    name: "Asatti Garden City",
    price: "66,353",
    detail: "Pahlawan Street XVII No.215, Cinangka, Sawangan, Depok, Jawa Barat",
    image: img2
  },
  {
    name: "Citralan Puri Serang",
    price: "35,853",
    detail: "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    image: img3
  }
]
export default Slider;