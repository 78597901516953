import React, { useState } from 'react';
import Data from "../../utils/accordion";
import { MdOutlineArrowDropDown } from "react-icons/md";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

function AccordionItemComponent({ item, index }) {
  const [className, setClassName] = useState(null);

  return (
    <AccordionItem
      className={`accordionItem ${className}`}
      key={index}
      uuid={index}
    >
      <AccordionItemHeading>
        <AccordionItemButton className="flexCenter accordionButton">
          <AccordionItemState>
            {({ expanded }) =>
              expanded ? setClassName("expanded") : setClassName("collapsed")
            }
          </AccordionItemState>

          <div className="flexCenter icon">{item.icon}</div>
          <span className="primaryText">{item.heading}</span>
          <div className="flexCenter icon">
            <MdOutlineArrowDropDown size={20} />
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p className="secondaryText">{item.detail}</p>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

function Mapping() {
  return (
    <Accordion className="accordion" allowMultipleExpanded={false} preExpanded={[0]}>
      {Data.map((item, i) => (
        <AccordionItemComponent key={i} item={item} index={i} />
      ))}
    </Accordion>
  );
}

export default Mapping;
