import React from 'react'
import Header from './component/Header/Header'
import './app.css'
import './index.css'
import Hero from './component/Hero/Hero'
import Companies from './component/Companies/Companies'
import Residencies from './component/Residencies/Residence'
import Value from './component/Value/Values';
import Contact from './component/Contact/Contact'
import GetStarted from './component/GetStarted/GetStarted'
import Footer from './component/Footer/Footer'
function App() {
  return (
    <div className="App">
    <div> 
        <div className="white-gradient" />
        <Header />
        <Hero />
    </div>
        <Companies /> 
        <Residencies />
        <Value/>
        <Contact/>
       <GetStarted/> 
       <Footer/>
    </div>
  )
}

export default App