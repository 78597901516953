import React, { useState } from "react";
import "./Value.css";
import Image from "../../images/value.png";

import "react-accessible-accordion/dist/fancy-example.css";
import Mapping from "./Mapping";

function Values() {
  return (
    <section className="v-wrapper">
      <div className="paddings innerWidth flexCenter v-container">
        {/* ledtside */}
        <div className="flexCenter v-left">
          <div className="img-container">
            <img src={Image} alt="value " />
          </div>
        </div>
        {/* rightside */}
        <div className="flexColStart v-right">
          <span className="orangeText">Our Value</span>
          <span className="primaryText">Value We Give to You</span>
          <span className="secondaryText">
            We always ready to help by providijng the best services for you.{" "}
            <br />
            We beleive a good blace to live can make your life better
          </span>
            <Mapping/>
        </div>
      </div>
    </section>
  );
}

export default Values;
